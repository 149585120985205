<template>
  <button :type="type" v-on="$listeners" class="px-2.5 py-1.5 border border-transparent rounded shadow-sm text-base text-center transition" :class="outlined ? 'text-gray-800 bg-white border-black hover:bg-gray-500' : 'text-white bg-gray-800 hover:bg-gray-500'">
    <font-awesome-icon :icon="icon" v-if="icon != ''" />
    {{ text }}
  </button>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPlus, faMinus);

export default {
  name: "BaseBtn",
  components: { FontAwesomeIcon },
  props: {
    icon: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "submit",
    },
    text: {
      type: String,
      default: "Submit",
    },
  },
};
</script>
